import EmojiConverter from 'emoji-js';
import appleDS from "emoji-datasource/img/apple/sheets/32.png";

let converter = new EmojiConverter();
converter.replace_mode = 'css';
converter.allow_native = false;
converter.use_sheet = true;
converter.img_set = 'apple';
converter.img_sets.apple.sheet = appleDS;

export default function (utf) {
    return converter.replace_unified(utf);
}
