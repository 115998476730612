<template>
    <span :style="'font-size:' + size"  v-html="realElement"></span>
</template>

<script>
    import emoji from './../plugins/emoji.js';

    export default {
        props: {
            utf: {
                type: String,
                required: true
            },
            size: {
                type: String,
                default: '24px'
            },
        },
        data () {
            return {
                emoji: emoji,
                realElement: null
            }
        },
        created () {
            this.realElement = this.emoji(this.utf);
        },
    }
</script>
