<template>
  <div id="app" class="" style="">
    <div v-if="currentWindow === 'landing'" style="min-width: 460px;">
        <Landing />
    </div>
    <div v-if="currentWindow === 'map'">
        <button @click="openLanding()" class="button is-rounded is-primary is-medium" style="position: absolute; left: 10px; top: 10px; z-index: 1000;">
            <Emoji class="mr-2 mt-1" utf="🐫"/>
            <strong>KarVan.Club</strong>
        </button>
        <Map />
    </div>
  </div>
</template>

<script>
import Map from './components/Map.vue';
import Landing from './components/Landing';
import Emoji from './widgets/Emoji.vue';
import { mapState } from "vuex";
import tt from './plugins/tt.js';

export default {
    name: 'App',
    components: {
        Map,
        Landing,
        Emoji
    },

    data: function () {
        return {
            tt: tt,
            currentLanguage: 'ua',
            loadingState: false
        };
    },

    computed: mapState({
        gold: 'ws.Gold',
        currentWindow: 'currentWindow',
        isLoading: 'isLoading'
    }),

    watch: {
        // isLoading: function (isLoading) {
        //     if (!isLoading && this.loadingState !== false) {
        //         this.loadingState.close();
        //         this.loadingState = false;
        //     } else if (isLoading) {
        //         this.loadingState = this.$buefy.loading.open();
        //     }
        // },
    },

    created () {
        this.currentLanguage = localStorage.getItem('lang') || 'ua';
    },

    mounted: function() {
        // this.loadingState = this.$buefy.loading.open();
    },

    methods: {
        openLanding () {
            window.history.replaceState(null, null,
                this.currentLanguage === 'ua' ? '/' : ('/' + this.currentLanguage)
            );
            this.$store.commit('setCurrentWindow', 'landing');
        }
    }
}
</script>

<style>
#app {

}
</style>
