export const messages = {
    'km': {
        'ua': 'км',
        'en': 'km',
        'ru': 'км',
    },
    'kmh': {
        'ua': 'км/год',
        'en': 'km/h',
        'ru': 'км/час',
    },
    'LandingSub': {
        'ua': 'оригінальна логістично-транспортна гра для telegram з картою всіх гравців та їх переміщень online',
        'en': 'an original logistics game for telegram with a map of all players and their movements online',
        'ru': 'оригинальная логистически-транспортная игра для telegram с картой всех игроков и их перемещений online',
    },
    'Map': {
        'ua': 'Мапа',
        'en': 'Map',
        'ru': 'Карта',
    },
    'Play': {
        'ua': 'Грати',
        'en': 'Play',
        'ru': 'Играть',
    },
    'NeedTg': {
        'ua': 'необхідно зайти через telegram',
        'en': 'need to enter via telegram',
        'ru': 'необходимо зайти через telegram',
    },
    'Cities': {
        'ua': 'Міста',
        'en': 'Cities',
        'ru': 'Города',
    },
    'Reputation': {
        'ua': 'реп',
        'en': 'rep',
        'ru': 'реп',
    },
    'RideKm': {
        'ua': 'км',
        'en': 'km',
        'ru': 'км',
    },
    'Transport': {
        'ua': 'Транспорт',
        'en': 'Transport',
        'ru': 'Транспорт',
    },
    'TransportInfo': {
        'ua': 'Список доступного транспорту у грі. Можна ознайомитися з характеристиками, поточним статусом та описом.',
        'en': 'List of available vehicles in the game. You can view the characteristics, current status and description.',
        'ru': 'Список доступного транспорта в игре. Можно ознакомиться с характеристиками, текущим статусом и описанием.',
    },
    'TransportNotOwned': {
        'ua': 'Транспорт недоступний!',
        'en': 'Transport not available!',
        'ru': 'Транспорт недоступен!',
    },
    'TransportNotOwnedInfo': {
        'ua': 'Підвищуйте репутацію у містах.',
        'en': 'Increase your reputation in cities.',
        'ru': 'Повышайте репутацию в городах.',
    },
    'City': {
        'ua': 'Місто',
        'en': 'City',
        'ru': 'Город',
    },
    'CityInfo': {
        'ua': 'Список міст доступних у грі. Можна ознайомитися з коротким описом, поточною погодою, станом ринку та корисною інформацією про місто.',
        'en': 'List of cities available in the game. You can read a brief description, current weather, market conditions and useful information about the city.',
        'ru': 'Список городов доступных в игре. Можно ознакомиться с кратким описание, текущей погодой, состоянием рынка и полезной информацией о городе.',
    },
    'CityMarket': {
        'ua': 'Стан Ринку',
        'en': 'Market Conditions',
        'ru': 'Состояние Рынка',
    },
    'CityMarketInfo': {
        'ua': 'Перелік товарів у місті, готових до продажу та купівлі. Актуальні ціни.',
        'en': 'List of goods in the city, ready for sale and purchase. Current prices.',
        'ru': 'Перечень товаров в городе, готовых к продаже и покупке. Актуальные цены.',
    },
    'CitySellPrice': {
        'ua': 'ціна продажу товару',
        'en': 'goods sell price',
        'ru': 'цена продажи товара',
    },
    'CityBuyPrice': {
        'ua': 'ціна придбання товару',
        'en': 'goods buy price',
        'ru': 'цена покупки товара',
    },
    'Info': {
        'ua': 'Інфо',
        'en': 'Info',
        'ru': 'Инфо',
    },
    'BookColorInfo': {
        'ua': 'забронюйте колір маркера на мапі',
        'en': 'reserve the marker color on the map',
        'ru': 'забронируйте цвет маркера на карте',
    },
    'BuyColor': {
        'ua': 'Забронювати колір',
        'en': 'Book color',
        'ru': 'Забронировать цвет',
    },
    'InventoryReputation': {
        'ua': 'Репутація в містах',
        'en': 'Reputation in the cities',
        'ru': 'Репутация в городах',
    },
    'CenteredSwitch': {
        'ua': 'центрувати маркер',
        'en': 'center marker',
        'ru': 'центрировать маркер',
    },
    'CenteredSwitchMessage': {
        'ua': 'екран частіше оновлюється, але центрований',
        'en': 'screen is updated more often, but centered',
        'ru': 'экран чаще обновляется, но всегда по центру',
    },
    'ScreensaverMessage': {
        'ua': 'секунд до наступного слайду',
        'en': 'seconds until next slide',
        'ru': 'секунд до следующего слайда',
    },
    'ActionItemBuy': {
        'ua': 'придбано в місті',
        'en': 'goods were purchased in',
        'ru': 'приобретен в городе'
    },
    'ActionItemSell': {
        'ua': 'продано в місті',
        'en': 'goods were sold in',
        'ru': 'продано в городе'
    },
    'ActionWhIn': {
        'ua': 'завантажено на склад',
        'en': 'loaded into the wh',
        'ru': 'загружен на склад'
    },
    'ActionWhOut': {
        'ua': 'в транспорт з',
        'en': 'into transport from',
        'ru': 'в транспорт с'
    },
    'ActionFuel': {
        'ua': 'повний бак',
        'en': 'full tank',
        'ru': 'полный бак'
    },
    'ActionRepair': {
        'ua': 'капітальний ремонт',
        'en': 'overhaul',
        'ru': 'капитальный ремонт'
    },
    'ActionApply': {
        'ua': 'контракт підписано',
        'en': 'the contract is signed',
        'ru': 'контракт подписан'
    },
    'ActionDone': {
        'ua': 'контракт виконано',
        'en': 'contract is fulfilled',
        'ru': 'контракт выполнен'
    },
    'ActionBreak': {
        'ua': 'контракт розірвано',
        'en': 'contract is terminated',
        'ru': 'контракт разорван'
    },
    'ActionAddTransport': {
        'ua': 'додано транспорт в',
        'en': 'transport added in',
        'ru': 'добавлен транспорт в'
    },
    'ActionAddGold': {
        'ua': 'додана валюта',
        'en': 'currency added',
        'ru': 'добавлена валюта'
    },
    'ActionDonateGold': {
        'ua': 'винагорода за донат',
        'en': 'reward for donation',
        'ru': 'вознаграждение за донат'
    },
    'ActionSetColor': {
        'ua': 'колір заброньовано',
        'en': 'color booked',
        'ru': 'цвет забронирован'
    },
    'ActionSetStatus': {
        'ua': 'встановлено новий статус',
        'en': 'a new status has been set',
        'ru': 'установлен новый статус'
    },
    'ActionAccident': {
        'ua': 'транспорт потрапив у аварію',
        'en': 'the vehicle had an accident',
        'ru': 'транспорт попал в аварию'
    },
    'ActionEvacuate': {
        'ua': 'транспорт евакуюють',
        'en': 'transport evacuated',
        'ru': 'транспорт эвакуируют'
    },
    'ActionDrive': {
        'ua': 'транспорт виїхав у',
        'en': 'transport left for',
        'ru': 'транспорт выехал в'
    },
    'ActionInCity': {
        'ua': 'транспорт прибув у',
        'en': 'transport arrived in',
        'ru': 'транспорт прибыл в'
    }
}
