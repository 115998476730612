import Vue from 'vue';
import Vuex from 'vuex';
import ws from './plugins/ws';
import { backmeta } from './backmeta.js';

Vue.use(Vuex);
Vue.use(ws);

export default new Vuex.Store({
    plugins: [ws.initPluginStore()],
    state: {
        globalFilter: '',
        currentWindow: 'landing',
        isLoading: true,
        authToken: null,
        backmeta: backmeta,
        placeholder: '/placeholder.jpg',
        cityModalId: null,
        isNavigationCentered: localStorage.getItem('isNavigationCentered') === '1',
        screensaverDelay: localStorage.getItem('screensaverDelay'),
        'ws.GameTick': 10,
        'ws.Goods': [],
        'ws.Cities': [],
        'ws.CityIcon': '🌇',
        'ws.Routes': [],
        'ws.Transport': {},
        'ws.PrevTransport': {},
        'ws.UserTransport': {},
        'ws.User': {},
        'ws.ActionLog': {},
        'ws.Users': {},
        'ws.Colors': {},
        'ws.OpenCity': null,
        'ws.OpenTransport': null,
    },

    mutations: {
        wsResponse(state, payload) {
            if (!payload.result) {
                return;
            }

            if (payload.responseKey === 'ws.Error') {
                Vue.notify({
                    group: 'main',
                    duration: 20000,
                    type:'error',
                    title: 'Error: ' + payload.result.methodName,
                    text: JSON.stringify(payload.result)
                });

                console.log(payload.result);

                return;
            }

            if (payload.result.updateObject) {
                if (!state[payload.responseKey] || state[payload.responseKey].length === 0) {
                    state[payload.responseKey] = { ...payload.result.updateObject };
                    return;
                }

                const oldState = state[payload.responseKey];
                const merged = mergeDeep(oldState, payload.result.updateObject);

                state[payload.responseKey] = { ...merged };
            } else {
                if (payload.responseKey === 'ws.Transport' && state['ws.Transport']) {
                    state['ws.PrevTransport'] = { ...state['ws.Transport'] };
                }

                state[payload.responseKey] = payload.result;
            }

        },
        updateGlobalFilter(state, filter) {
            state.globalFilter = filter;
        },
        setCurrentWindow(state, window) {
            state.currentWindow = window;
        },
        setLoading(state, loading) {
            state.isLoading = loading;
        },
        openCityModal(state, cityId) {
            state.cityModalId = cityId;
        },
        setAuthToken(state, token) {
            state.authToken = token;
        },
        openCityOnMap(state, cityId) {
            state['ws.OpenCity'] = cityId;
        },
        openTransportOnMap(state, transportId) {
            state['ws.OpenTransport'] = transportId;
        },
        setNavigationCentered(state, isCentered) {
            state.isNavigationCentered = isCentered;
            localStorage.setItem('isNavigationCentered', isCentered ? '1' : '0');
        },
        setScreensaverDelay(state, screensaverDelay) {
            state.screensaverDelay = screensaverDelay;
            localStorage.setItem('screensaverDelay', screensaverDelay);
        },
    },
});

export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key]) || (source[key] && source[key].length === 0)) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}
