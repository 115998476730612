import { messages } from './../messages.js';

export default function (key, fromObject = false) {
    const currentLang = localStorage.getItem('lang') || 'ua';
    if (fromObject) {
        return (fromObject[key] || {})[currentLang] || key;
    }

    return (messages[key] || {})[currentLang] || key;
}
