<template>
    <div class="box">
        <div class="level is-mobile" :class="{'mb-3': botUrl, 'mb-0': !botUrl }">
            <div class="level-left">
                <div class="level-item">
                    <button v-if="isUserAuth && botUrl" @click="openModal()" class="button is-outlined is-primary is-rounded">
                        <strong>{{ backmeta.cities[cityId].nameObject[currentLanguage] }}</strong>
                    </button>
                    <strong v-else-if="!botUrl" class="is-size-4">{{ backmeta.cities[cityId].nameObject[currentLanguage] }}</strong>
                    <b-tooltip
                        v-else
                        type="is-light"
                        :triggers="['click']"
                        :auto-close="['outside', 'escape']"
                        class="has-text-centered"
                        position="is-right"
                    >
                        <template v-slot:content>
                            <p>{{  tt('NeedTg') }}</p>
                            <a :href="botUrl" target="_blank">@KarvanClubBot</a>
                        </template>
                        <button class="button is-outlined is-primary is-rounded">
                            <strong>{{ backmeta.cities[cityId].nameObject[currentLanguage] }}</strong>
                        </button>
                    </b-tooltip>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <Emoji :key="cities[cityId] ? cities[cityId].weather.temp : ' - - '" :utf="getCityTempIcon()" class="mt-2 mr-1"/>
                    {{ cities[cityId] ? cities[cityId].weather.temp : ' - - ' }}°C
                </div>
                <div class="level-item">
                    <Emoji utf="🤝" class="mr-1 mt-2" />
                    {{ getCityReputation() }}
                </div>
            </div>
        </div>
        <div class="content">
            <template v-if="isUserAuth && showMarketCarousel">
                <b-carousel-list
                    :data="getMarketForCarousel()"
                    :items-to-show="2"
                    :repeat="true"
                    v-model="marketCarousel"
                >
                    <template #item="marketItem">
                        <div class="box p-1 m-1 mb-1">
                            <h5 class="has-text-centered title is-6 mb-0">
                                <Emoji class="mr-1" :utf="goods[marketItem.slug].emoji" size="20px" />
                                <span  style="vertical-align: 20%">{{ goods[marketItem.slug].name[currentLanguage] }}</span>
                            </h5>
                            <div class="level is-mobile">
                                <div class="level-left">
                                    <div class="level-item" :title="tt('CitySellPrice')" style="display: inline-block;">
                                        <Emoji utf="📤" size="18px" class="mr-1"/>
                                        <strong style="vertical-align: 15%">{{ marketItem.sell > 0 ? ('+' + marketItem.sell) : ' - - '}}</strong>
                                        <Emoji utf="💰" size="20px"/>
                                    </div>
                                </div>
                                <div class="level-right">
                                    <div class="level-item" :title="tt('CityBuyPrice')" style="display: inline-block;">
                                        <Emoji utf="📥" size="18px" class="mr-1"/>
                                        <strong style="vertical-align: 15%">{{ marketItem.buy > 0 ? ('-' + marketItem.buy) : ' - - '}}</strong>
                                        <Emoji utf="💰" size="20px"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </b-carousel-list>
            </template>
            <p v-else>
                {{ backmeta.cities[cityId].aboutObject[currentLanguage]}}
            </p>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    // import Vue from 'vue';
    import tt from './../plugins/tt.js';
    import Emoji from './../widgets/Emoji.vue';

    export default {
        components: {
            Emoji
        },
        props: ['cityId', 'botUrl', 'showMarketCarousel'],
        data () {
            return {
                tt: tt,
                currentLanguage: 'ua',
                currentCityInfoIndex: 0,
                currentCityInfo: null,
                isUserAuth: false,
                marketCarousel: 0,
                playMarketCarouselTimer: null,
            }
        },
        computed: mapState({
            backmeta: 'backmeta',
            authToken: 'authToken',
            cities: 'ws.Cities',
            goods: 'ws.Goods',
            user: 'ws.User',
        }),
        watch: {
            authToken: function (newAuthToken) {
                this.isUserAuth = newAuthToken ? true : false;
            },
            cities: function () {
                this.currentCityInfo = this.cities[this.cityId].info[0] || null;
            },
            isModalActive: function () {
                if (this.isModalActive) {
                    this.initCityInfo();
                }
            },
            marketCarousel: function() {
                this.playMarketCarousel();
            },
            showMarketCarousel: function() {
                if (!this.showMarketCarousel) {
                    return;
                }

                this.playMarketCarousel();
            }
        },
        created () {
            this.currentLanguage = localStorage.getItem('lang') || 'ua';
            if (this.authToken) {
                this.isUserAuth = true;
            }

            this.playMarketCarousel();
        },
        methods: {
            getCityReputation () {
                if (this.user.reputationObject) {
                    return this.user.reputationObject[this.cityId] || ' - - ';
                }
                return ' - - ';
            },
            getCityTempIcon() {
                return this.cities[this.cityId] ? this.cities[this.cityId].weather.icon : '🌝';
            },
            openModal() {
                this.$store.commit('openCityModal', this.cityId);
            },
            getMarketForCarousel()
            {
                let market = [];
                if (!this.cities[this.cityId]) {
                    return market;
                }

                for (const marketSlug in this.cities[this.cityId].market) {
                    let marketItem = this.cities[this.cityId].market[marketSlug];
                    marketItem['slug'] = marketSlug;
                    market.push(marketItem);
                }

                return market;
            },
            playMarketCarousel() {
                if (this.playMarketCarouselTimer) {
                    clearTimeout(this.playMarketCarouselTimer);
                }

                this.playMarketCarouselTimer = setTimeout(() => {
                    if (!this.cities[this.cityId]) {
                        return;
                    }

                    if (!this.showMarketCarousel) {
                        return;
                    }

                    const carouselLenght = Object.keys(this.cities[this.cityId].market).length;
                    if (carouselLenght <= 0) {
                        return;
                    }

                    if ((this.marketCarousel + 1) < carouselLenght) {
                        this.marketCarousel += 1;
                    } else {
                        this.marketCarousel = 0;
                    }
                }, 3000);
            }
        }
    }
</script>
