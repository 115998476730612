<template>
    <div class="container is-max-desktop mt-5">
        <div class="section columns">
            <div class="column" style="padding: 0;">
                <a :href="'/' + (currentLanguage !== 'ua' ? currentLanguage : '')">
                    <h1 class="title ml-4">KarVan.Club</h1>
                </a>
                <h2 class="subtitle ml-4">
                    <a :href="getBotUrl()" target="_blank">@KarvanClubBot</a> {{ tt('LandingSub') }}
                </h2>
                <div ref="tgInfoPost" v-show="showTgInfo || !isUserAuth"></div>
                <div class="buttons is-centered mt-4">
                    <a :href="getBotUrl()" target="_blank">
                        <button class="button is-rounded is-primary is-light">
                            <Emoji class="mr-2 mt-1" utf="🐫" /> {{ tt('Play') }}
                        </button>
                    </a>
                    <button v-if="isUserAuth" @click="openMap()" class="button is-rounded is-success is-medium">
                        <Emoji class="mr-2 mt-2" utf="🌎" /> {{ tt('Map') }}
                    </button>
                    <b-tooltip v-else type="is-light" :triggers="['click']" :auto-close="['outside', 'escape']"
                        class="has-text-centered">
                        <template v-slot:content>
                            <p>{{ tt('NeedTg') }}</p>
                            <a :href="getBotUrl()" target="_blank">@KarvanClubBot</a>
                        </template>
                        <button class="button is-rounded is-success is-medium">
                            <Emoji class="mr-2 mt-2" utf="🌎" /> {{ tt('Map') }}
                        </button>
                    </b-tooltip>
                    <a href="#cities">
                        <button class="button is-rounded">
                            <Emoji class="mr-2 mt-2" :utf="cityIcon" :key="cityIcon" /> {{ tt('Cities') }}
                        </button>
                    </a>
                </div>
                <div class="buttons is-centered">
                    <a href="#topReputation">
                        <button class="button is-rounded"><strong>TOP100</strong>
                            <Emoji class=" mt-1" utf="🤝" />{{ tt('Reputation') }}
                        </button>
                    </a>
                    <button v-if="isUserAuth" @click="togleTgInfo()" class="button is-rounded"
                        :class="{ 'is-ghost': !showTgInfo }">
                        <Emoji class=" mt-2" utf="📰" />
                    </button>
                    <a href="#topRide">
                        <button class="button is-rounded"><strong>TOP100</strong>
                            <Emoji class="mr-1 mt-1" utf="👣" />{{ tt('RideKm') }}
                        </button>
                    </a>
                </div>
            </div>
            <div class="column" v-show="showTgInfo || !isUserAuth">
                <div v-for="(post, index) in newsPosts" :key="index" :ref="'tgNews' + index"></div>
            </div>
        </div>

        <div class="section container mb-4">
            <h1 class="title has-text-centered">
                <Emoji utf="🚘" size="20px" class="mr-1" />
                <span>{{ tt('Transport') }}</span>
            </h1>
            <h2 class="subtitle has-text-centered">{{ tt('TransportInfo') }}</h2>
            <div class="columns is-multiline is-centered">
                <div class="column is-6" v-for="(transport, transportSlug) in backmeta.transport"
                    :key="userTransport[transportSlug] ? userTransport[transportSlug].id : transportSlug">
                    <Transport :slug="transportSlug"
                        :transportId="userTransport[transportSlug] ? userTransport[transportSlug].id : null"
                        :botUrl="getBotUrl()" />
                </div>
            </div>
        </div>

        <div class="section container" id="cities">
            <h1 class="title has-text-centered">
                <Emoji :key="cityIcon" :utf="cityIcon" size="20px" class="mr-1" />
                <span>{{ tt('Cities') }}</span>
                <a href="#"><button class="button is-ghost">ᛏ</button></a>
            </h1>
            <h2 class="subtitle has-text-centered">{{ tt('CityInfo') }}</h2>
            <div class="columns is-multiline is-centered">
                <div class="column is-6" v-for="(city, cityId) in backmeta.cities" :key="'city' + cityId">
                    <City :cityId="cityId" :botUrl="getBotUrl()" :key="'cityElement' + cityId" />
                </div>
            </div>
        </div>
        <CityModal />

        <div class="section columns" style="padding-top: 2em;" >
            <div class="column" id="topReputation">
                <h1 class="title has-text-centered">
                    <span style="vertical-align: 10%;" >TOP100 </span>
                    <Emoji utf="🤝" size="24px" class="" />
                    <span style="vertical-align: 15%;" > {{ tt('Reputation') }}</span>
                    <a href="#"><button class="button is-ghost">ᛏ</button></a>
                </h1>
                <h2 class="subtitle has-text-centered">WIP</h2>
            </div>
            <div class="column" id="topRide">
                <h1 class="title has-text-centered">
                    <span>TOP100 </span>
                    <Emoji utf="👣" size="24px" class="" />
                    <span> {{ tt('RideKm') }}</span>
                    <a href="#"><button class="button is-ghost">ᛏ</button></a>
                </h1>
                <h2 class="subtitle has-text-centered">WIP</h2>
            </div>
        </div>
        <div class="section columns is-mobile">
            <div class="column ml-2 is-size-7">
                <p>
                    <strong class="">© 2024 KarVan.Club</strong>
                </p>
                <p>
                    <a target="_blank" :href="getBotUrl()">@KarvanClubBot </a>
                    <a target="_blank" href="https://t.me/KarvanClubNews">@KarvanClubNews</a>
                </p>
            </div>
            <div class="column has-text-right mt-3 mr-2 is-size-5">
                <a class="mr-4" href="/">
                    <strong v-if="currentLanguage == 'ua'">UA</strong>
                    <span v-else>UA</span>
                </a>
                <a class="mr-4" href="/en">
                    <strong v-if="currentLanguage == 'en'">EN</strong>
                    <span v-else>EN</span>
                </a>
                <a href="/ru">
                    <strong v-if="currentLanguage == 'ru'">RU</strong>
                    <span v-else>RU</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    // import Vue from 'vue';
    import tt from './../plugins/tt.js';
    import Emoji from './../widgets/Emoji.vue';
    import Transport from './Transport.vue';
    import City from './City.vue';
    import CityModal from './CityModal.vue';

    export default {
        components: {
            Emoji,
            Transport,
            City,
            CityModal,
        },
        data () {
            return {
                tt: tt,
                currentLanguage: 'ua',
                isUserAuth: false,
                showTgInfo: true,
                infoPost: {
                    ua: 'KarvanClubNews/8',
                    en: 'KarvanClubNews/9',
                    ru: 'KarvanClubNews/10',
                },
                newsPosts: [
                    {
                        ua: 'KarvanClubNews/2',
                        en: 'KarvanClubNews/6',
                        ru: 'KarvanClubNews/7',
                    },
                    {
                        ua: 'KarvanClubNews/11',
                        en: 'KarvanClubNews/12',
                        ru: 'KarvanClubNews/13',
                    },
                    {
                        ua: 'KarvanClubNews/15',
                        en: 'KarvanClubNews/15',
                        ru: 'KarvanClubNews/15',
                    }
                ]
            }
        },
        computed: mapState({
            authToken: 'authToken',
            backmeta: 'backmeta',
            cityIcon: 'ws.CityIcon',
            userTransport: 'ws.UserTransport',
        }),
        watch: {
            authToken: function (newAuthToken) {
                this.isUserAuth = newAuthToken ? true : false;
            },
        },
        created () {
            this.currentLanguage = localStorage.getItem('lang') || 'ua';
            if (this.authToken) {
                this.isUserAuth = true;
            }

            const storedShowTgInfo = localStorage.getItem('showTgInfo');
            if (storedShowTgInfo !== null) {
                this.showTgInfo = storedShowTgInfo === '1' ? true : false;
            }
        },
        mounted () {
            this.initTgInfo();

            this.newsPosts.forEach((post, index) => {
                const tgPost = document.createElement('script');
                tgPost.src = 'https://telegram.org/js/telegram-widget.js?22';
                tgPost.setAttribute('data-telegram-post', post[this.currentLanguage]);
                tgPost.setAttribute('data-width', '100%');
                this.$refs['tgNews' + index][0].appendChild(tgPost);
            });
        },
        methods: {
            initTgInfo () {
                const tgInfoPost = document.createElement('script');
                tgInfoPost.src = 'https://telegram.org/js/telegram-widget.js?22';
                tgInfoPost.setAttribute('data-telegram-post', this.infoPost[this.currentLanguage]);
                tgInfoPost.setAttribute('data-width', '100%');
                tgInfoPost.setAttribute('data-userpic', 'false');
                this.$refs.tgInfoPost.appendChild(tgInfoPost);
            },
            getBotUrl () {
                let botUrl = 'https://t.me/KarvanClubBot';
                const refId = parseInt(localStorage.getItem('r'));
                if (refId) {
                    botUrl += '?start=' + refId;
                }

                return botUrl;
            },
            openMap () {
                window.history.replaceState(null, null,
                    (this.currentLanguage === 'ua' ? '/' : ('/' + this.currentLanguage + '/')) + 'map'
                );
                this.$store.commit('setCurrentWindow', 'map');
            },
            togleTgInfo () {
                this.showTgInfo = !this.showTgInfo;
                localStorage.setItem('showTgInfo', this.showTgInfo ? 1 : 0);
            }
        }
    }
</script>
