import Vue from 'vue';
import App from './App.vue';
import store from './store.js';
import Buefy from 'buefy';
import Notifications from 'vue-notification';
import '@mdi/font/css/materialdesignicons.css'
import 'buefy/dist/buefy.css';
import 'leaflet/dist/leaflet.css';
import './assets/app.css';

Vue.use(Buefy);
Vue.use(Notifications);
Vue.config.productionTip = false;

// Fix for Leaflet icons
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

new Vue({
  store,
  render: h => h(App),
}).$mount('#app');
