export default {
    serverHost: process.env.VUE_APP_WS_URL || 'wss://ws.karvan.club',

    store: null,
    ws: null,
    disconnectCount: 0,
    tryReconnect: false,

    initPluginStore () {
        return store => { this.store = store; };
    },

    install (Vue) {
        let currentLang = localStorage.getItem('lang');
        const pathParts = window.location.pathname.split('/');

        let pathLang = 'ua';
        let pathMethodIndex = 1;
        if (['en', 'ru'].includes(pathParts[1])) {
            pathLang = pathParts[1];
            pathMethodIndex = 2;
        }
        let pathMethod = pathParts[pathMethodIndex];
        if (!['', 'map', 'open-map', 'u', 'r'].includes(pathMethod)) {
            pathMethod = '';
        }
        if (pathLang !== currentLang) {
            localStorage.setItem('lang', pathLang);
        }
        if (pathLang !== 'ua') {
            document.documentElement.setAttribute('lang', pathLang);
        }

        if (['open-map', 'u'].includes(pathMethod)) {
            const newAuthToken = window.location.hash.split('#')[1] || null;
            window.location.hash = '';

            if (newAuthToken) {
                localStorage.setItem('authToken', newAuthToken);
            }
            if (pathMethod === 'u') {
                pathMethod = '';
            } else {
                pathMethod = 'map';
            }
        } else if (pathMethod === 'r') {
            const newRefId = pathParts[pathMethodIndex + 1] || null;
            if (newRefId) {
                localStorage.setItem('r', newRefId);
            }
            pathMethod = '';
        }

        let newPath = pathMethod;
        if (pathLang !== 'ua') {
            newPath = pathLang;
            if (pathMethod !== '') {
                newPath += '/' + pathMethod;
            }
        }

        const currentAuthToken = localStorage.getItem('authToken');
        if (!currentAuthToken) {
            if (pathLang === 'ua') {
                window.history.replaceState(null, null, '/');
            } else {
                window.history.replaceState(null, null, '/' + pathLang);
            }
            return;
        }
        window.history.replaceState(null, null, '/' + newPath);

        this.tryReconnect = true;
        this.ws = new WebSocket(this.serverHost + '/ws?token=' + currentAuthToken);
        this.ws.onopen = () => {
            Vue.notify({
                group: 'main',
                title: 'WebSocket',
                text: 'WebSocket connected!'
            });

            this.store.commit('setAuthToken', currentAuthToken);
            this.store.commit('setLoading', false);

            if (pathMethod === 'map') {
                this.store.commit('setCurrentWindow', 'map');
            }

            while (this.requestsStack.length > 0) {
                let request = this.requestsStack.pop();
                if (request.method) {
                    this.wsSend(request.method, request.params);
                }
            }
        };
        this.ws.onclose = () => {
            this.store.commit('setAuthToken', null);
            this.store.commit('setLoading', true);

            if (!this.tryReconnect) {
                Vue.notify({
                    group: 'main',
                    duration: 100000,
                    type: 'error',
                    title: 'WebSocket',
                    text: 'WebSocket disconnected!'
                });

                return;
            }

            this.disconnectCount++;

            if (this.disconnectCount > 60) { // ~5m waiting, after - reload page
                location.reload();
            }

            Vue.notify({
                group: 'main',
                duration: 10000,
                type: 'error',
                title: 'WebSocket',
                text: 'WebSocket disconnected! Try again after 5 seconds...'
            });

            setTimeout(() => {
                this.install(Vue);
            }, 5000); // WS reconnection delay
        };
        this.ws.onmessage = event => {
            const data = JSON.parse(event.data);

            if (data.method === 'InvalidToken') {
                this.tryReconnect = false;
                localStorage.removeItem('authToken');
                this.store.commit('setAuthToken', null);
                this.store.commit('setCurrentWindow', 'landing');

                if (pathLang === 'ua') {
                    window.history.replaceState(null, null, '/');
                } else {
                    window.history.replaceState(null, null, '/' + pathLang);
                }
                return;
            }

            if (this.pendingRequests[data.id]) {
                let request = this.pendingRequests[data.id];
                request['resultHandler'](request['responseStoreKey'], data.result);
                delete this.pendingRequests[data.id];
            } else {
                this.store.commit('wsResponse', {
                    responseKey: 'ws.' + data.method,
                    result: data.params
                });
            }
        };
        Vue.prototype.$wsCall = (method, params) => {
            if (this.ws.readyState === WebSocket.OPEN) {
                this.wsSend(method, params);
            } else {
                this.requestsStack.push({method, params});
            }
        };
    },

    requestsStack: [],
    requestId: 0,
    pendingRequests: [],

    wsSend(method, params) {
        const currentId = this.getCurrentRequestId();
        this.ws.send(JSON.stringify({
            jsonrpc: '2.0',
            method: method,
            params: params,
            id: currentId
        }));

        this.pendingRequests[currentId] = {
            resultHandler: (responseKey, result) => {
                this.store.commit('wsResponse', { responseKey, result });
            },
            responseStoreKey: 'ws.' + method
        };
    },

    getCurrentRequestId() {
        if (this.requestId > 1000000) {
            this.requestId = 0;
        }

        this.requestId++;

        return this.requestId;
    }
}
